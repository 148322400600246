atmProjects-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

atmProjects-badge.deploymentType-blue {
  background: lightblue;
  color: darkblue;

}

atmProjects-badge.deploymentType-green {

  background: #C8E6C9;
  color: #256029;

}

atmProjects-badge.processingCenter-alpha {
  background: lightyellow;
  color: yellow;

}

atmProjects-badge.processingCenter-omega {

  background: lightgrey;
  color: black;

}


.datatable-editing-demo .editable-cells-table td.p-cell-editing {
  padding-top: 0;
  padding-bottom: 0;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007ad9 !important;
  background: #007ad9 !important;
}

.atmTMFloatButton {
  position: fixed;
  top: 100px;
  left: 85%;
}

/* table.p-datatable-table {

} */

tr>td {
  flex: 1 1 3em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

tr>th {
  flex: 1 1 3em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* selection checkbox */
tr>td:nth-child(1) {
  flex: 0 0 3em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* selection checkbox */
tr>th:nth-child(1) {
  flex: 0 0 3em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

tr>td:nth-child(2) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* selection checkbox */
tr>th:nth-child(2) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* action */
tr>td:nth-child(3) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* action */
tr>th:nth-child(3) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* processingCenter */
tr>td:nth-child(4) {
  flex: 0 0 13em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* processingCenter */
tr>th:nth-child(4) {
  flex: 0 0 13em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* deplomentType */
tr>td:nth-child(5) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* deplomentType */
tr>th:nth-child(5) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* targetInstalDate */
tr>td:nth-child(6) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* targetInstalDate */
tr>th:nth-child(6) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* amacProjectNumber */
tr>td:nth-child(7) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* amacProjectNumber */
tr>th:nth-child(7) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* conversionStatus */
tr>td:nth-child(8) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* conversionStatus */
tr>th:nth-child(8) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* statusDate */
tr>td:nth-child(9) {
  flex: 0 0 11em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* statusDate */
tr>th:nth-child(9) {
  flex: 0 0 11em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstatonId */
tr>td:nth-child(10) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstatonId */
tr>th:nth-child(10) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* subsidiaryName */
tr>td:nth-child(11) {
  flex: 0 0 20em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* subsidiaryName */
tr>th:nth-child(11) {
  flex: 0 0 20em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstationLocation */
tr>td:nth-child(12) {
  flex: 0 0 18em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstationLocation */
tr>th:nth-child(12) {
  flex: 0 0 18em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* street */
tr>td:nth-child(13) {
  flex: 0 0 30em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* street */
tr>th:nth-child(13) {
  flex: 0 0 30em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* houseNumber */
tr>td:nth-child(14) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* houseNumber */
tr>th:nth-child(14) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* city */
tr>td:nth-child(15) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* city */
tr>th:nth-child(15) {
  flex: 0 0 12em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* state */
tr>td:nth-child(16) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* state */
tr>th:nth-child(16) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* zipcode */
tr>td:nth-child(17) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* zipcode */
tr>th:nth-child(17) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* timeZone */
tr>td:nth-child(18) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* timeZone */
tr>th:nth-child(18) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* LOB */
tr>td:nth-child(19) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* LOB */
tr>th:nth-child(19) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* branchNumber */
tr>td:nth-child(20) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* branchNumber */
tr>th:nth-child(20) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* FIID */
tr>td:nth-child(21) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* FIID */
tr>th:nth-child(21) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* active */
tr>td:nth-child(22) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* active */
tr>th:nth-child(22) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* manufacturer */
tr>td:nth-child(23) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* manufacturer */
tr>th:nth-child(23) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* model */
tr>td:nth-child(24) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* model */
tr>th:nth-child(24) {
  flex: 0 0 7em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*Workstation Profile*/
tr>td:nth-child(25) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*Workstation Profile*/
tr>th:nth-child(25) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* terminalLocId */
tr>td:nth-child(26) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* terminalLocId */
tr>th:nth-child(26) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstationTag */
tr>td:nth-child(27) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* workstationTag */
tr>th:nth-child(27) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* referenceCurrency */
tr>td:nth-child(28) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* referenceCurrency */
tr>th:nth-child(28) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*consumerGroupSortOrder*/
tr>td:nth-child(29) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*consumerGroupSortOrder*/
tr>th:nth-child(29) {
  flex: 0 0 10em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}


/* routingCode */
tr>td:nth-child(30) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* routingCode */
tr>th:nth-child(30) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* accountNumber */
tr>td:nth-child(31) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* accountNumber */
tr>th:nth-child(31) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* terminalOwner */
tr>td:nth-child(32) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* terminalOwner */
tr>th:nth-child(32) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* custServiceNo */
tr>td:nth-child(33) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* custServiceNo */
tr>th:nth-child(33) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* description */
tr>td:nth-child(34) {
  flex: 0 0 13em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* description */
tr>th:nth-child(34) {
  flex: 0 0 13em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* acceptorId */
tr>td:nth-child(35) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* acceptorId */
tr>th:nth-child(35) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}


/* eppprofileid */
tr>td:nth-child(36) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* eppprofileid */
tr>th:nth-child(36) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* eppid */
tr>td:nth-child(37) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* eppid */
tr>th:nth-child(37) {
  flex: 0 0 8em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* realATMIPAddress */
tr>td:nth-child(38) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* realATMIPAddress */
tr>th:nth-child(38) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* gatewayIPAddress */
tr>td:nth-child(39) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* gatewayIPAddress */
tr>th:nth-child(39) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* subnetMask */
tr>td:nth-child(40) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* subnetMask */
tr>th:nth-child(40) {
  flex: 0 0 9em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*Base 24 Conversion Data*/
tr>td:nth-child(41) {
  flex: 0 0 30em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*Base 24 Conversion Data*/
tr>th:nth-child(41) {
  flex: 0 0 30em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* statusDetails */
tr>td:nth-child(42) {
  flex: 0 0 50em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

/* statusDetails */
tr>th:nth-child(42) {
  flex: 0 0 50em !important;
  flex-direction: row;
  flex-wrap: nowrap;
}

td[role=cell] {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

td[role=cell]:hover {
  color: var(--loginBackGroud);
  background-color: var(--token-background-primary);
  overflow-x: visible !important;
  overflow-y: visible;
  white-space: wrap;
  text-overflow: unset !important;
  flex-wrap: wrap;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.cellerror {
  outline: var(--token-text-color-error) dotted thin;
  outline-offset: -4px;
  color: var(--token-text-color-error);
  font-weight: 550;

}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.installStatus.success {
  color: var(--token-text-color-confirmation);
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.installStatus.warning {
  color: var(--token-text-color-tertiary);

}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.installStatus.error {
  outline-offset: -4px;
  color: var(--token-text-color-error);
  font-weight: 550;

}


.p-datatable.p-datatable-sm .p-datatable-tbody>tr.readerror {
  outline-offset: -2px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr.readerror .p-checkbox .p-checkbox-box {
  border-color: transparent;
  background-color: transparent;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr.invalidRow>td.p-selection-column {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" overflow="visible" stroke="white" stroke-width="1"><line x2="19" y2="19" /><line x1="19" y2="19" /></svg>');

}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr .invalidRow>td {
  border-style: double;
  border-color: red;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr.installStatus_success .p-checkbox .p-checkbox-box {
  border-color: transparent;
  background-color: transparent;
  z-index: 0;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr.invalidRow .p-checkbox .p-checkbox-box {
  border-color: transparent;
  background-color: transparent;
  z-index: 0;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td>div:hoover {
  font-weight: lighter;
  width: 12em;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td>div.installStatusDetails:hover div {
  font-weight: 550;
  width: 12em;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.success>div.installStatusDetails div {
  visibility: hidden;
  font-weight: 550;
  overflow: hidden;
  color: var(--token-color-brand-primary);
  font-weight: 550;
  width: 12em;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td.success>div.installStatusDetails:hoover div {
  visibility: hidden;
  font-weight: 550;
  overflow: hidden;
  color: var(--token-color-brand-primary);
  font-weight: 550;
  width: 12em;
}

.p-datatable .p-datatable-tbody>tr>td,
.p-datatable-resizable-table>.p-datatable-thead>tr>th {
  text-align: left;
  border: 1px solid var(--token-border-base);
  border-width: 1px;
  padding: 0.571rem 0.857rem;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;

}

.p-datatable .p-datatable-tbody>tr>td.p-cell-editing.p-editable-column {
  border-top-color: var(--token-border-interaction);
  border-right-color: var(--token-border-interaction);
  border-bottom-color: var(--token-border-interaction);
  border-left-color: var(--token-border-interaction);

}

.p-datatable .p-datatable-tbody>tr>td.p-selection-column {
  border-right-color: transparent;
}

.p-datatable .p-datatable-tbody>tr>td.readyStatus {
  border-left-color: transparent;

}

@media screen and (min-width: 672px){
.usb-modal--container .modal-dialog {
    height: auto;
    width: 75vw;
    max-width: 49.5rem;
}
}
@media screen and (min-width: 672px){
.usb-modal--container .modal-content {
    max-width: 40vw;
    height: auto;
    -moz-border-radius: .75rem;
    border-radius: .75rem;
}}
.pad1 {
  padding: 1em;
} 
.modal-header {
  background-color: var(--token-color-grey-10);
}
select {
  min-height: 2em;
}
select:focus-visible,select:focus, option:focus-visible {
  margin: 0;
  -moz-box-shadow: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
  border-bottom: none !important;
  border: 2px solid var(--token-color-outline) !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;  
}
tr>td:has(p):hover {
  color: var(--token-text-color-error);
  font-weight: 550;
  display: block  !important;
}
p.error {
  border-width: 3;
  padding: auto;
  border-color: var(--token-text-color-error);
 
}
p.error:hover {
  border-width: 3;
  padding: auto;
  border-color: var(--token-text-color-error);
  color: var(--token-text-color-error);
  font-weight: 550;
  display: block  !important;
  text-wrap: wrap;

}